import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';

const colors = {
  keyops: {
    teal: '#39BDAF',
    blue: '#3376D1',
    darkBlue: '#2c5282',
  },
};

const customTheme = extendTheme({
  colors,

  components: {
    Alert: {
      variants: {
        // Custom theme for Toast. Only applies to `solid` variant
        solid: (props: StyleFunctionProps) => {
          const { colorScheme } = props;
          return {
            container: {
              bg: `${colorScheme}.100`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            },
            title: {
              color: 'gray.700',
            },
            description: {
              color: 'gray.700',
            },
            icon: {
              color: `${colorScheme}.500`,
              alignItems: 'center',
            },
          };
        },
      },
    },
  },
});

export default customTheme;
