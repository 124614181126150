import React, { useState } from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { FiTrash } from 'react-icons/fi';
import {
  Box,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from '@chakra-ui/react';
import {
  ComparableEngagements,
  ComparableQuestions as ComparableQuestionsEntity,
} from '@keyops-cep/api-client';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import { CustomToast } from '../../../components/CustomToast';
import Loading from '../../../components/Loading';
import {
  deleteOneSurveysReport,
  generateAISurveysReport,
} from '../../../utils/functions/compare.utils';
import lang from '../../../utils/lang';

import { ComparableQuestions } from './comparableQuestions';

type ComparisonsTabsProps = {
  comparableEngagements: ComparableEngagements;
  currentEngagementName: string;
  comparableEngagementName: string;
  setComparableEngagements: React.Dispatch<
    React.SetStateAction<ComparableEngagements | undefined>
  >;
};

export const ComparisonsTabs = ({
  comparableEngagements,
  currentEngagementName,
  comparableEngagementName,
  setComparableEngagements,
}: ComparisonsTabsProps) => {
  // We need to set the list of ComparableQuestions here, since they can be deleted one by one in questionComparisonCard
  // And needs to be re-set after each delete
  const [allComparableQuestions, setAllComparableQuestions] = useState<
    ComparableQuestionsEntity[]
  >(comparableEngagements?.comparableQuestions);

  const { addToast } = CustomToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleDeleteComparativeReport = async () => {
    try {
      setLoading(true);
      const result = await deleteOneSurveysReport(comparableEngagements.id);

      if (result.status >= 200 && result?.data) {
        setComparableEngagements({
          ...comparableEngagements,
          report: undefined,
        });
        setLoading(false);
        addToast(
          lang.engagement.single.comparisonAnalysis.globalReport
            .successfulDelete,
          `success`
        );
      }
    } catch (error) {
      setLoading(false);
      addToast(lang.errors.default, `error`);
    }
  };

  const handleRefreshComparativeReport = async () => {
    try {
      setLoading(true);
      const result = await generateAISurveysReport(comparableEngagements.id);

      if (result.status >= 200 && result?.data) {
        setLoading(false);
        setComparableEngagements({
          ...comparableEngagements,
          report: result.data,
        });
      }
    } catch (error) {
      setLoading(false);
      addToast(lang.errors.default, `error`);
    }
  };

  return (
    <Tabs defaultIndex={comparableEngagements?.report ? 0 : 1} m={5}>
      <TabList>
        <Tab>{lang.engagement.single.comparisonAnalysis.linkTitle}</Tab>
        <Tab> {lang.engagement.single.comparisonAnalysis.questions.title}</Tab>
      </TabList>
      <TabIndicator bg="keyops.blue" />
      <TabPanels p={0}>
        <TabPanel p={0}>
          <Stack m={5} ml={0} direction={'row'}>
            <Box w={'100%'}>
              {isLoading ? (
                <Loading h="300px" />
              ) : (
                // TODO: create a custom component so it can be easier to mock
                <MDEditor.Markdown
                  style={{ backgroundColor: 'transparent' }}
                  source={
                    comparableEngagements?.report ??
                    lang.engagement.single.comparisonAnalysis.globalReport
                      .notFound
                  }
                  rehypePlugins={[rehypeSanitize]}
                />
              )}
            </Box>
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              spacing={3}
              color={'keyops.darkBlue'}
            >
              {comparableEngagements?.report && (
                <Tooltip
                  _hover={{ cursor: 'pointer' }}
                  label={
                    lang.engagement.single.comparisonAnalysis.globalReport
                      .delete
                  }
                  fontSize="12px"
                  placement="top"
                  hasArrow
                  shouldWrapChildren
                >
                  <FiTrash
                    data-testid="comparable-eng-report-delete-btn"
                    onClick={() => handleDeleteComparativeReport()}
                  />
                </Tooltip>
              )}

              <Tooltip
                _hover={{ cursor: 'pointer' }}
                label={
                  lang.engagement.single.comparisonAnalysis.globalReport
                    .regenerateReport
                }
                fontSize="12px"
                placement="top"
                hasArrow
                shouldWrapChildren
              >
                <FaWandMagicSparkles
                  data-testid="comparable-eng-report-regenerate-btn"
                  onClick={() => handleRefreshComparativeReport()}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel p={0}>
          <ComparableQuestions
            comparableQuestions={allComparableQuestions}
            currentEngagementName={currentEngagementName}
            comparableEngagementName={comparableEngagementName}
            setAllComparableQuestions={setAllComparableQuestions}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
