// External Dependencies
import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

const Loading = ({
  h = '100vh',
  size = '120px',
}: {
  h?: string;
  size?: string;
}) => {
  return (
    <Center h={h}>
      <CircularProgress
        data-testid="loading"
        aria-label="progress"
        isIndeterminate
        size={size}
        color="keyops.blue"
        thickness={8}
      />
    </Center>
  );
};

export default Loading;
