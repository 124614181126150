import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Admin } from '@keyops-cep/api-client';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosError } from 'axios';

import { CreateUserDto } from '../../api/dto/user.dto';
import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import GenericButton from '../../components/GenericButton';
import Loading from '../../components/Loading';
import { dateFormatter } from '../../utils/functions/common-utils';
import { ADMINS_PAGE, ROOT } from '../../utils/internal-routes';
import lang from '../../utils/lang';
import { UserModal } from '../Users/components/userModal';

import { AdminsTable } from './components/adminsTable';

//Build table logic
export type AdminsTableHead = {
  id: string;
  displayName: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  actions: string;
};

export const buildAdminsTableColumns = () => {
  const columnHelper = createColumnHelper<AdminsTableHead>();
  const columns = [
    columnHelper.accessor('displayName', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: 'Email',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: 'Creation date',
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Id',
    }),
    columnHelper.accessor('actions', {
      cell: (info) => (
        <Text
          display="flex"
          justifyContent="space-between"
          alignContent="space-between"
        >
          <FaEdit />
          {/* TODO: add a delete action here */}
          <MdDelete />
        </Text>
      ),
      header: 'Actions',
    }),
  ];

  return columns;
};

const buildAdminsTableData = (admins: Admin[]) => {
  const data: AdminsTableHead[] = [];
  admins.forEach((admin) =>
    data.push({
      id: admin.id,
      displayName: admin.displayName,
      email: admin.email,
      createdAt: new Date(admin.createdAt),
      updatedAt: new Date(admin.createdAt),
      actions: '<p><p>',
    })
  );

  return data;
};

const Admins: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);

  // Handle notification toast (error, success...)
  const { addToast } = CustomToast();

  // Handle modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [admins, setAdmins] = useState([] as Admin[]);
  const adminsData: AdminsTableHead[] = buildAdminsTableData(admins);

  const fetchAdmins = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.adminsApi.adminControllerFindAll();
      if (response) {
        setAdmins(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`fetchAdmins` + error);
      const toastMessage =
        error instanceof Error
          ? error.message
          : 'An unexpected error occurred. Please try again.';
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
      setIsLoading(false);
    }
  }, [addToast]);
  useEffect(() => {
    fetchAdmins();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFormSubmit = async (values: Admin) => {
    const adminDto = values as Admin;

    try {
      const response = await apiClient.adminsApi.adminControllerCreate(
        adminDto
      );

      if (response?.status === 201) {
        await fetchAdmins();
        onClose();
        const toastMessage = `The admin '${adminDto.displayName}' has been created!`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      let toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      if (error instanceof AxiosError && error.response) {
        const errorMessages = error.response.data.message;
        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((msg: string) => {
            toastMessage += msg + ' \n';
          });
        } else {
          toastMessage = errorMessages;
        }
      }
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <>
      <CustomBreadcrumb
        list={[
          { text: 'Home', link: ROOT },
          {
            text: 'All admins',
            link: ADMINS_PAGE,
          },
        ]}
      />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        paddingLeft={5}
        paddingRight={5}
      >
        <Heading as="h1" fontSize={24} lineHeight={'32px'}>
          {lang.admin.all.title}{' '}
        </Heading>
        {/* TODO:add tests */}
        <GenericButton
          type="button"
          text={lang.admin.add.action}
          onClick={onOpen}
        />
      </Stack>
      {isLoading ? <Loading /> : <AdminsTable data={adminsData ?? {}} />}

      <UserModal
        tenant={undefined}
        isAdmin={true}
        isOpen={isOpen}
        onClose={onClose}
        onFormSubmit={
          onFormSubmit as (values: Admin | CreateUserDto) => Promise<void>
        }
      />
    </>
  );
};

export default Admins;
