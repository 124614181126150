import { ComparableQuestions } from '@keyops-cep/api-client';

import { apiClient } from '../../api/swagger-codegen-api-config';

export function sortComparableQuestionsAscending(
  comparableQuestions: ComparableQuestions[],
  currentEngagementId: string
) {
  // Sort the comparableQuestions array in place
  comparableQuestions.sort((a, b) => {
    // Find the question with the currentId in each comparableQuestions object
    const questionA = a.questions.find(
      (question) => question.engagementId === currentEngagementId
    );
    const questionB = b.questions.find(
      (question) => question.engagementId === currentEngagementId
    );

    // Use the 'position' property to compare and sort in descending order
    // If the currentId is not found in one of the questions arrays, treat its position as Infinity to ensure it sorts to the end
    const positionA = questionA ? questionA.position : Infinity;
    const positionB = questionB ? questionB.position : Infinity;

    // Ascending order comparison
    return positionA - positionB;
  });

  return comparableQuestions;
}

// Ask AIs to run comparisons for a given comparableEngagements (produce a general analysis + list comparableQuestions + produce an analysis of each comparableQuestions)
export const runAIComparisons = async (comparableEngagementId: string) => {
  return await apiClient.comparableEngagementsApi.comparableEngagementsControllerRunAIComparisons(
    comparableEngagementId
  );
};

export const deleteOneComparableQuestions = async (
  comparableQuestionId: string
) => {
  return await apiClient.comparableQuestionsApi.comparableQuestionsControllerDeleteOne(
    comparableQuestionId
  );
};

// Ask AIs to generate a comparative report for a given comparableQuestions
export const generateAIQuestionsReport = async (
  comparableQuestionsId: string
) => {
  return await apiClient.comparableQuestionsApi.comparableQuestionsControllerGenerateComparativeReport(
    comparableQuestionsId
  );
};

export const deleteOneQuestionsReport = async (
  comparableQuestionId: string
) => {
  return await apiClient.comparableQuestionsApi.comparableQuestionsControllerDeleteComparativeReport(
    comparableQuestionId
  );
};

export const deleteOneSurveysReport = async (
  comparableEngagementsId: string
) => {
  return await apiClient.comparableEngagementsApi.comparableEngagementsControllerDeleteComparativeReport(
    comparableEngagementsId
  );
};

// Ask AIs to generate a comparative report for a given comparableQuestions
export const generateAISurveysReport = async (
  comparableEngagementsId: string
) => {
  return await apiClient.comparableEngagementsApi.comparableEngagementsControllerGenerateComparativeReport(
    comparableEngagementsId
  );
};
