import React, { useEffect, useState } from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { FiTrash } from 'react-icons/fi';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Divider,
  Heading,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ComparableQuestions,
  Question,
  QuestionAnnotation,
} from '@keyops-cep/api-client';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import { CustomToast } from '../../../components/CustomToast';
import Loading from '../../../components/Loading';
import {
  deleteOneComparableQuestions,
  deleteOneQuestionsReport,
  generateAIQuestionsReport,
} from '../../../utils/functions/compare.utils';
import lang from '../../../utils/lang';

type QuestionsComparisonCardProps = {
  comparableQuestions: ComparableQuestions;
  currentEngagementName: string;
  comparableEngagementName: string;
  prevKey: number;
  setAllComparableQuestions: React.Dispatch<
    React.SetStateAction<ComparableQuestions[]>
  >;
};
export const QuestionsComparisonCard = ({
  comparableQuestions,
  currentEngagementName,
  comparableEngagementName,
  prevKey,
  setAllComparableQuestions,
}: QuestionsComparisonCardProps) => {
  const { addToast } = CustomToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  // Accordion on questions report
  const [showMoreReport, setShowMoreReport] = useState<boolean>(false);
  const [report, setReport] = useState<string | undefined>(
    comparableQuestions?.report
  );

  const handleDeleteOneComparableQuestions = async () => {
    try {
      setLoading(true);
      const result = await deleteOneComparableQuestions(comparableQuestions.id);

      if (result.status >= 200 && result?.data) {
        setAllComparableQuestions(result.data);
        setLoading(false);
        addToast(
          lang.engagement.single.comparisonAnalysis.questions.successfulDelete,
          `success`
        );
      }
    } catch (error) {
      setLoading(false);
      addToast(lang.errors.default, `error`);
    }
  };

  const handleRefreshOneComparableQuestions = async () => {
    try {
      setLoading(true);
      const result = await generateAIQuestionsReport(comparableQuestions.id);

      if (result.status >= 200 && result?.data) {
        setReport(result.data);
        formatReport(report);
        setLoading(false);
        addToast(
          lang.engagement.single.comparisonAnalysis.questions.successfulReport,
          `success`
        );
      }
    } catch (error) {
      setLoading(false);
      addToast(lang.errors.default, `error`);
    }
  };

  const handleDeleteOneComparableQuestionsReport = async () => {
    try {
      setLoading(true);
      const result = await deleteOneQuestionsReport(comparableQuestions.id);

      if (result.status >= 200 && result?.data) {
        setReport('');
        setLoading(false);
        addToast(
          lang.engagement.single.comparisonAnalysis.questions
            .successfulDeleteReport,
          `success`
        );
      }
    } catch (error) {
      setLoading(false);
      addToast(lang.errors.default, `error`);
    }
  };

  const handleShowReportToggle = () => {
    setShowMoreReport(!showMoreReport);
  };

  useEffect(() => {
    formatReport(report);
  }, [report]);

  // Comparative report: we only display the conclusion sentence by default and have to click on "Read more" to see the full report
  const formatReport = (report: string | undefined) => {
    const summarizedReport = report?.split('**In summary**:')[1] ?? '';
    const fullReport = report?.split('**In summary**:')[0] ?? report;

    return { summarizedReport, fullReport };
  };

  const { summarizedReport, fullReport } = formatReport(report);

  return (
    <Stack
      direction={'row-reverse'}
      justifyContent={'start'}
      alignContent={'center'}
      mb={3}
    >
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        p={2}
      >
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          spacing={3}
          color={'keyops.darkBlue'}
        >
          <Tooltip
            _hover={{ cursor: 'pointer' }}
            label={lang.engagement.single.comparisonAnalysis.questions.delete}
            fontSize="12px"
            placement="top"
            hasArrow
            shouldWrapChildren
          >
            <FiTrash
              data-testid="cq-delete-btn"
              onClick={() => handleDeleteOneComparableQuestions()}
            />
          </Tooltip>

          <Tooltip
            _hover={{ cursor: 'pointer' }}
            label={
              lang.engagement.single.comparisonAnalysis.questions
                .regenerateReport
            }
            fontSize="12px"
            placement="top"
            hasArrow
            shouldWrapChildren
          >
            <FaWandMagicSparkles
              data-testid="cq-regenerate-btn"
              onClick={() => handleRefreshOneComparableQuestions()}
            />
          </Tooltip>
        </Stack>
      </Stack>
      <Card key={prevKey} variant="elevated" p={3} w={'100%'}>
        {isLoading ? (
          <Loading h={'100px'} />
        ) : (
          <Accordion allowToggle border={'none'} p={0}>
            <AccordionItem border={'none'}>
              <AccordionButton justifyContent={'space-between'}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  spacing={7}
                >
                  {comparableQuestions.questions.map(
                    (question: Question, key: number) => (
                      <Stack direction={'column'} key={key} w={'50%'}>
                        <Heading
                          as={'h4'}
                          fontSize={12}
                          textAlign="left"
                          color="gray.700"
                          textTransform={'capitalize'}
                          fontWeight={400}
                        >
                          {key === 0
                            ? currentEngagementName
                            : comparableEngagementName}
                        </Heading>
                        <Heading as={'h3'} fontSize={16} textAlign="left">
                          {`Question ${question.position + 1}`}
                        </Heading>
                      </Stack>
                    )
                  )}
                </Stack>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {/* Question label */}
                <Stack direction={'row'} spacing={3}>
                  {comparableQuestions.questions.map(
                    (question: Question, key: number) => (
                      <Stack direction={'column'} key={key} w={'50%'}>
                        <Text fontSize={16} textAlign="left" h={'max-content'}>
                          {question.label}
                        </Text>
                      </Stack>
                    )
                  )}
                </Stack>

                {/* Question type */}
                <Stack direction={'row'} spacing={3}>
                  {comparableQuestions.questions.map(
                    (question: Question, key: number) => (
                      <Stack direction={'column'} key={key} w={'50%'}>
                        <Heading
                          as={'h3'}
                          fontSize={16}
                          textAlign="left"
                          pt={3}
                        >
                          {
                            lang.engagement.single.comparisonAnalysis.questions
                              .type
                          }
                        </Heading>

                        <Text fontSize={16} textAlign="left">
                          {question.type +
                            (question.subType ? ' - ' + question.subType : '')}
                        </Text>
                      </Stack>
                    )
                  )}
                </Stack>

                {/* Question themes */}
                <Stack direction={'row'} spacing={3}>
                  {comparableQuestions.questions.map(
                    (question: Question, key: number) => (
                      <Stack direction={'column'} key={key} w={'50%'}>
                        <Heading
                          as={'h3'}
                          fontSize={16}
                          textAlign="left"
                          pt={3}
                        >
                          {
                            lang.engagement.single.comparisonAnalysis.questions
                              .themes
                          }
                        </Heading>

                        <Text fontSize={16} textAlign="left">
                          {question.themes}
                        </Text>
                      </Stack>
                    )
                  )}
                </Stack>

                {/* QuestionAnnotation */}
                <Stack direction={'row'} spacing={3}>
                  {comparableQuestions.questions.map(
                    (question: Question, key: number) =>
                      question?.questionAnnotations?.length > 0 && (
                        <Stack direction={'column'} key={key} w={'50%'}>
                          <Heading
                            as={'h3'}
                            fontSize={16}
                            textAlign="left"
                            pt={3}
                          >
                            {
                              lang.engagement.single.comparisonAnalysis
                                .questions.annotations
                            }
                          </Heading>

                          {question.questionAnnotations.map(
                            (questionAnnotation: QuestionAnnotation, key) => (
                              <Text key={key} fontSize={16} textAlign="left">
                                {questionAnnotation.name}:{' '}
                                {questionAnnotation.value} (
                                {questionAnnotation.description})
                              </Text>
                            )
                          )}
                        </Stack>
                      )
                  )}
                </Stack>

                <Divider mt={5} mb={5} />
                {/* AI remarks aboout the questions pair */}
                {comparableQuestions?.notes && (
                  <Stack direction={'row'} spacing={3}>
                    <Stack direction={'column'} w={'100%'}>
                      <Heading as={'h3'} fontSize={16} textAlign="left">
                        {
                          lang.engagement.single.comparisonAnalysis.questions
                            .notes
                        }
                      </Heading>

                      <Text fontSize={16} textAlign="left">
                        {comparableQuestions.notes}
                      </Text>
                    </Stack>
                  </Stack>
                )}
                {/* AI report aboout the questions pair */}
                {report && (
                  <Stack direction={'row'} spacing={3}>
                    <Stack direction={'column'} w={'100%'}>
                      <Accordion allowToggle>
                        <AccordionItem border={'none'}>
                          <Heading
                            as={'h3'}
                            fontSize={16}
                            textAlign="left"
                            pt={3}
                            pb={3}
                          >
                            {
                              lang.engagement.single.comparisonAnalysis
                                .questions.comparisonReport
                            }
                          </Heading>
                          <MDEditor.Markdown
                            style={{ backgroundColor: 'transparent' }}
                            source={summarizedReport}
                            rehypePlugins={[rehypeSanitize]}
                          />

                          <AccordionButton
                            justifyContent={'flex-start'}
                            cursor={'default'}
                            _hover={{
                              backgroundColor: 'none',
                            }}
                            color={'keyops.blue'}
                            p={'8px 0 0 0'}
                            m={0}
                            fontSize={'14px'}
                            fontWeight={'400'}
                            onClick={() => handleShowReportToggle()}
                          >
                            {showMoreReport ? lang.showLess : lang.showMore}
                            <AccordionIcon />
                          </AccordionButton>

                          <AccordionPanel pb={4}>
                            <MDEditor.Markdown
                              style={{ backgroundColor: 'transparent' }}
                              source={fullReport}
                              rehypePlugins={[rehypeSanitize]}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                    <Box color={'keyops.darkBlue'}>
                      <Tooltip
                        _hover={{ cursor: 'pointer' }}
                        label={
                          lang.engagement.single.comparisonAnalysis.questions
                            .deleteReport
                        }
                        fontSize="12px"
                        placement="top"
                        hasArrow
                        shouldWrapChildren
                      >
                        <FiTrash
                          data-testid="cq-delete-report-btn"
                          onClick={() =>
                            handleDeleteOneComparableQuestionsReport()
                          }
                        />
                      </Tooltip>
                    </Box>
                  </Stack>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
      </Card>
    </Stack>
  );
};
