import { CepApiClient, getAxiosInterceptor } from '@keyops-cep/api-client';
import axios from 'axios';

import { getEnvironmentVariables } from '../utils/functions/env-utils';
import { getAccessToken } from '../utils/get-tokens';
import { LOGIN } from '../utils/internal-routes';

import { removeTokens, setNewTokens } from './functions/auth.functions';

const { REACT_APP_API_URL_SWAGGER } = getEnvironmentVariables();
const accessToken = getAccessToken();

// Response Interceptor to globally handle errors
const axiosInterceptor = getAxiosInterceptor(
  setNewTokens,
  removeTokens,
  LOGIN,
  axios
);

// Handle the BE errors to reformat and display them
axios.interceptors.response.use(axiosInterceptor.responseHandler, (error) => {
  const formattedError = new Error();
  formattedError.message =
    error?.response?.data?.message ??
    error?.detailed ??
    error?.message ??
    (typeof error === 'string' ? error : 'An unexpected error occurred.');
  formattedError.name = error?.error ?? 'Error';

  return Promise.reject(formattedError);
});

export const apiClient = new CepApiClient(
  REACT_APP_API_URL_SWAGGER,
  accessToken ?? '',
  axios
);
